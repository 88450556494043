

<template>
  <v-main>
    <Overlay :overlay="showLoading" :color="envSelected.color.name"></Overlay>
    <v-container fluid>
      <v-card>
        <v-row no-gutters>
          <v-col>
            <v-breadcrumbs v-if="breadcrumbs && breadcrumbs.length > 0" :items="breadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
                  @click="reRouteFromBreadCrumb(item, $event)"
                >
                  {{ item.title }}
                </v-breadcrumbs-item>
              </template>
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
      </v-card>
      <v-row no-gutters>
        <v-col>
          <Messages :messages="globalMessages"></Messages>
        </v-col>
      </v-row>
    </v-container>
    <router-view
        ref="applicationBodyWrapper"
        @triggerOverlayLoading="handleShowLoading"
        :envSelected="envSelected"
        :environments="environments"
        @switchEnv="switchEnvironments"
        :saasFeatures="saasFeatures"
        :enterpriseFeatures="enterpriseFeatures"
        @beginTour="beginTour"
        :selectedTab="selectedTab"
    />
  </v-main>
</template>

<script>

  import globalMixins from "../../../../mixins/globalMixins";

  export default {
    name: 'DashboardCoreView',

    mixins: [globalMixins],

    props: {
      saasFeatures: {
        type: Array
      },
      enterpriseFeatures: {
        type: Array
      },
      environments: {
        type: Array
      },
      selectedTab: {
        type: Number
      },
      'tourOn': {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        breadcrumbs: null,
        l0: []
      };
    },

    computed: {
      showLoading: function () {
        return this.globalLoading;
      }
    },

    components: {
      Overlay: () => import('../../../../components/OverlayLoading'),
      Messages: () => import('../../../../components/Messages'),
    },

    methods: {
      handleShowLoading: function (loadingValue) {
        this.globalLoading = loadingValue;
      },

      reRouteFromBreadCrumb: function (breadcrumb, $event) {
        let params = this.$router.currentRoute.params;
        params['envCode']=this.envSelected.value;
        let link = {name: breadcrumb.href, params: params};
        this.$router.push(link);
        $event.preventDefault();
        return false;
      },

      switchEnvironments: function(item, $event){
        this.$emit('switchEnv', item);
      },

      beginTour(){
        this.$emit('beginTour', true);
      }
    },

    created() {
      if (this.$router.currentRoute.meta && this.$router.currentRoute.meta.section === 'environment') {
        this.l0 = [{title: this.envSelected.name.toUpperCase()}];
      }
      else {
        this.l0 = [];
      }

      if (this.$router.currentRoute.meta && this.$router.currentRoute.meta.breadcrumb) {
        this.breadcrumbs = this.l0.concat(this.$router.currentRoute.meta.breadcrumb);
      }

    },

    watch: {

      tourOn(val){
        this.handleShowLoading(val);
      },

      envSelected(newVal, oldVal) {
        if (newVal.value !== oldVal.value) {
          this.l0 = [{title: newVal.name}];
          this.breadcrumbs.shift();
          this.breadcrumbs = this.l0.concat(this.breadcrumbs);
        }
      },

      $route(to, from) {
        if (to.name === from.name && to.fullPath !== from.fullPath) {
          if(Object.hasOwnProperty.call(this.$refs.applicationBodyWrapper, 'initialize')){
            this.$refs.applicationBodyWrapper.initialize();
          }
        }

        if (this.$router.currentRoute.meta && this.$router.currentRoute.meta.section === 'environment') {
          this.l0 = [{title: this.envSelected.name.toUpperCase()}];
        }
        else {
          this.l0 = [];
        }

        if (to.meta && to.meta.breadcrumb) {
          this.breadcrumbs = this.l0.concat(to.meta.breadcrumb);
        }
      }
    }
  }
</script>

<!--<style scoped>
  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
  }
</style>-->
